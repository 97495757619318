import React from "react";

import Icon from "./Icon";

const MicrosoftIcon = (props) => (
    <Icon viewBox="0 0 19 18" className={props.className} width={props.width} height={props.height}>
        <rect x="0.68689" width="8.46322" height="8.46322" fill="#F25022" />
        <rect x="0.68689" y="9.33327" width="8.46322" height="8.46322" fill="#00A4EF" />
        <rect x="10.0201" y="9.33327" width="8.46322" height="8.46322" fill="#FFB900" />
        <rect x="10.0201" width="8.46322" height="8.46322" fill="#7FBA00" />
    </Icon>
);

export default MicrosoftIcon;
