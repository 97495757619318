import React from "react";
import Icon from "./Icon";

const JelouLogoIcon = (props) => (
  <Icon
    viewBox="0 0 185 49"
    className={props.className}
    width={props.width}
    height={props.height}
    style={props.style}
  >
    <path
      d="M18.4321 0C14.8243 0 11.8952 2.92429 11.8952 6.52622C11.8952 10.1281 14.8243 13.0524 18.4321 13.0524C22.04 13.0524 24.9691 10.1281 24.9691 6.52622C25.0048 2.92429 22.04 0 18.4321 0Z"
      fill="#00B3C7"
    />
    <path
      d="M18.4321 0C14.8243 0 11.8952 2.92429 11.8952 6.52622C11.8952 10.1281 14.8243 13.0524 18.4321 13.0524C22.04 13.0524 24.9691 10.1281 24.9691 6.52622C25.0048 2.92429 22.04 0 18.4321 0Z"
      fill="#00B3C7"
    />
    <path
      d="M96.5543 2.85297H87.4812V47.9658H96.5543V2.85297Z"
      fill="#00B3C7"
    />
    <path
      d="M96.5543 2.85297H87.4812V47.9658H96.5543V2.85297Z"
      fill="#00B3C7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.7262 11.8042C71.9782 11.8042 80.1226 20.1135 80.1226 30.3843C80.1226 31.9779 79.8374 33.429 79.7001 34.1268L79.6972 34.1417C79.6821 34.2185 79.6689 34.2858 79.6582 34.3428C79.6582 34.3428 54.1533 34.1645 52.5458 34.1645C52.9745 35.7693 53.9033 36.9818 54.8677 38.0517C56.8324 40.1914 59.3329 41.3326 62.1192 41.3326C64.4053 41.3326 66.7272 40.4411 68.549 38.8719L75.0859 43.2584C71.6924 46.8959 66.8343 48.9644 61.7262 48.9644C51.4742 48.9644 43.1155 40.655 43.1155 30.3843C43.1155 20.1492 51.4385 11.8042 61.7262 11.8042ZM52.3672 27.1034C52.3606 27.1234 52.3526 27.1535 52.3441 27.1891C52.3306 27.2459 52.3156 27.3168 52.3021 27.3838C52.2789 27.4991 52.2601 27.6026 52.2601 27.6026L71.9425 27.6383L71.7995 27.1747C71.3352 25.4272 70.4779 23.8581 69.2991 22.6099C67.4416 20.5772 64.8339 19.4003 62.1192 19.4003C59.4043 19.4003 56.8324 20.5415 54.832 22.6099C53.7961 23.8581 52.8316 25.4272 52.3672 27.1034Z"
      fill="#00B3C7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.7262 11.8042C71.9782 11.8042 80.1226 20.1135 80.1226 30.3843C80.1226 31.9779 79.8374 33.429 79.7001 34.1268L79.6972 34.1417C79.6821 34.2185 79.6689 34.2858 79.6582 34.3428C79.6582 34.3428 54.1533 34.1645 52.5458 34.1645C52.9745 35.7693 53.9033 36.9818 54.8677 38.0517C56.8324 40.1914 59.3329 41.3326 62.1192 41.3326C64.4053 41.3326 66.7272 40.4411 68.549 38.8719L75.0859 43.2584C71.6924 46.8959 66.8343 48.9644 61.7262 48.9644C51.4742 48.9644 43.1155 40.655 43.1155 30.3843C43.1155 20.1492 51.4385 11.8042 61.7262 11.8042ZM52.3672 27.1034C52.3606 27.1234 52.3526 27.1535 52.3441 27.1891C52.3306 27.2459 52.3156 27.3168 52.3021 27.3838C52.2789 27.4991 52.2601 27.6026 52.2601 27.6026L71.9425 27.6383L71.7995 27.1747C71.3352 25.4272 70.4779 23.8581 69.2991 22.6099C67.4416 20.5772 64.8339 19.4003 62.1192 19.4003C59.4043 19.4003 56.8324 20.5415 54.832 22.6099C53.7961 23.8581 52.8316 25.4272 52.3672 27.1034Z"
      fill="#00B3C7"
    />
    <path
      d="M36.8643 30.527C36.8643 40.7264 28.6127 49 18.4678 49C8.7517 49 0.571546 41.19 0 31.5255H9.68045C9.93048 33.4869 10.7878 35.2701 12.1809 36.6609C13.8598 38.337 16.146 39.2999 18.4678 39.2999C20.754 39.2999 22.933 38.3726 24.6476 36.6609C26.3265 34.9848 27.2195 32.8806 27.2195 30.4913V13.2664H36.8643V30.527Z"
      fill="#00B3C7"
    />
    <path
      d="M36.8643 30.527C36.8643 40.7264 28.6127 49 18.4678 49C8.7517 49 0.571546 41.19 0 31.5255H9.68045C9.93048 33.4869 10.7878 35.2701 12.1809 36.6609C13.8598 38.337 16.146 39.2999 18.4678 39.2999C20.754 39.2999 22.933 38.3726 24.6476 36.6609C26.3265 34.9848 27.2195 32.8806 27.2195 30.4913V13.2664H36.8643V30.527Z"
      fill="#00B3C7"
    />
    <path
      d="M185 30.42C185 40.2984 176.427 49 166.639 49C156.852 49 148.243 40.2984 148.243 30.3843V13.0524H157.352V30.099C157.352 32.7023 158.281 35.3414 159.924 37.3741C161.781 39.3356 164.246 40.4767 166.639 40.4767C169.033 40.4767 171.533 39.2999 173.319 37.3384C174.962 35.4127 175.927 32.7737 175.927 30.099V13.0524H185V30.42Z"
      fill="#00B3C7"
    />
    <path
      d="M185 30.42C185 40.2984 176.427 49 166.639 49C156.852 49 148.243 40.2984 148.243 30.3843V13.0524H157.352V30.099C157.352 32.7023 158.281 35.3414 159.924 37.3741C161.781 39.3356 164.246 40.4767 166.639 40.4767C169.033 40.4767 171.533 39.2999 173.319 37.3384C174.962 35.4127 175.927 32.7737 175.927 30.099V13.0524H185V30.42Z"
      fill="#00B3C7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.948 30.4556C102.948 20.2562 111.664 11.9469 122.345 11.9469C133.097 11.9469 141.849 20.2562 141.813 30.4556C141.813 40.8334 133.276 48.9644 122.345 48.9644C117.13 48.9644 112.236 47.0742 108.592 43.5794C104.949 40.0845 102.948 35.4484 102.948 30.4556ZM122.452 41.4753C125.31 41.4753 127.81 40.4054 129.954 38.2657C131.883 36.1616 132.918 33.3799 132.918 30.42C132.918 27.4956 131.847 24.821 129.918 22.7882C127.846 20.6128 125.31 19.5073 122.416 19.5073C119.595 19.5073 116.915 20.7198 115.129 22.7526C113.093 24.714 111.95 27.46 111.95 30.4913C111.95 33.5226 113.058 36.2329 115.094 38.2657C116.951 40.2984 119.63 41.4753 122.452 41.4753Z"
      fill="#00B3C7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.948 30.4556C102.948 20.2562 111.664 11.9469 122.345 11.9469C133.097 11.9469 141.849 20.2562 141.813 30.4556C141.813 40.8334 133.276 48.9644 122.345 48.9644C117.13 48.9644 112.236 47.0742 108.592 43.5794C104.949 40.0845 102.948 35.4484 102.948 30.4556ZM122.452 41.4753C125.31 41.4753 127.81 40.4054 129.954 38.2657C131.883 36.1616 132.918 33.3799 132.918 30.42C132.918 27.4956 131.847 24.821 129.918 22.7882C127.846 20.6128 125.31 19.5073 122.416 19.5073C119.595 19.5073 116.915 20.7198 115.129 22.7526C113.093 24.714 111.95 27.46 111.95 30.4913C111.95 33.5226 113.058 36.2329 115.094 38.2657C116.951 40.2984 119.63 41.4753 122.452 41.4753Z"
      fill="#00B3C7"
    />
  </Icon>
);
export default JelouLogoIcon;
