export const APPLICATIONS = {
    PMA: "pma",
    APPS: "apps",
};

export const SERVER_URLS = {
    PMA_PASSWORDLESS: "https://api.jelou.ai/v1/auth/login/passwordless" || process.env.REACT_APP_PMA_PASSWORDLESS,
    APPS_SSO: "http://apps.jelou.ai/sso/" || process.env.REACT_APP_APPS_SSO,
    PMA_IMPERSONATE: "https://pma.jelou.ai/impersonate/",
};
