import axios from "axios";
import dayjs from "dayjs";
import get from "lodash/get";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";

import J from "./components/Icons/J";
import { APPLICATIONS, SERVER_URLS } from "./constans";
import JelouLogoIcon from "./components/Icons/JelouLogoIcon";
import MicrosoftIcon from "./components/Icons/MicrosoftIcon";
import BanisiLogoIcon from "./components/Icons/BanisiLogoIcon";

const renderErrors = (errors, t) => {
    if (!isEmpty(errors)) {
        return <span className="text-xs font-bold text-red">{errors}</span>;
    }
};

function App() {
    const [time, setTime] = useState(dayjs().locale("es").format("HH:mm"));

    const { instance, accounts } = useMsal();
    const [loadingPMA, setLoadingPMA] = useState(false);
    const [loadingApps, setLoadingApps] = useState(false);
    const [errors, setErrors] = useState([]);

    // Set the time every second
    useEffect(() => {
        const id = setInterval(function () {
            setTime(dayjs().locale("es").format("HH:mm"));
        }, 10 * 1000);
        return () => {
            clearInterval(id);
        };
    }, []);

    /**
     * Method to handle the login of Azure AD
     * when the login is successful, it will redirect to the PMA/APPS application
     *
     * @param {*} account
     */
    async function handleAzureLogin({ account, application, byPassAzure }) {
        let impersonateUrl = "";

        // Set up intent variables
        if (application === APPLICATIONS.APPS) {
            impersonateUrl = SERVER_URLS.APPS_SSO;
        } else {
            impersonateUrl = SERVER_URLS.PMA_IMPERSONATE;
        }

        // Check if testing environment

        // Avoid using azure login, pass directly to the application
        if (byPassAzure) {
            const token = localStorage.getItem("token");
            const requestBody = {
                payload: { secret: token, application, validateSecret: false },
            };

            // Fetch for User JWT Token
            try {
                const token = await axios.post(SERVER_URLS.PMA_PASSWORDLESS, requestBody);
                // We clear loading
                setLoadingPMA(false);
                setLoadingApps(false);

                // We clear local storage
                localStorage.clear();
                return (window.location = impersonateUrl + get(token, "data.data.token"));
            } catch (error) {
                const errorData = get(error, "response.data");
                const message = get(errorData, "error.clientMessages.es", "Ha ocurrido un error inesperado. Inténtelo nuevamente.");
                setErrors(message);
                setLoadingPMA(false);
                setLoadingApps(false);
                console.log("Error Logging in App", error);
            }
        } else {
            instance
                .acquireTokenSilent({
                    scopes: ["User.Read"],
                    account: account,
                })
                .then(async (response) => {
                    // Build the payload to send to the server
                    const requestBody = {
                        payload: { secret: response.idToken, application },
                    };

                    // Fetch for User JWT Token
                    try {
                        const token = await axios.post(SERVER_URLS.PMA_PASSWORDLESS, requestBody);

                        // We clear loading
                        setLoadingPMA(false);
                        setLoadingApps(false);

                        // We clear local storage
                        localStorage.clear();
                        return (window.location = impersonateUrl + get(token, "data.data.token"));
                    } catch (error) {
                        const errorData = get(error, "response.data");
                        const message = get(errorData, "error.clientMessages.es", "Ha ocurrido un error inesperado. Inténtelo nuevamente.");
                        setErrors(message);
                        setLoadingPMA(false);
                        setLoadingApps(false);
                        console.log("Error Logging in Apps", error);
                    }
                })
                .catch((error) => {
                    const errorData = get(error, "response.data");
                    const message = get(errorData, "error.clientMessages.es", "Ha ocurrido un error inesperado. Inténtelo nuevamente.");
                    setErrors(message);
                    setLoadingPMA(false);
                    console.log("Error Azure AD", error);
                });
        }
    }

    // Use Effect for accounts from MSAL updated
    useEffect(() => {
        // Extract the application selected
        const application = localStorage.getItem("application") || APPLICATIONS.APPS;

        // Extract first account from the array
        const account = first(accounts);

        // If account is retrieved -> We try login in to System
        if (!isEmpty(account)) handleAzureLogin({ account, application });
    }, [accounts]);

    /**
     * Method to handle on click event for Azure Login
     * Used in both PMA and APPS buttons
     *
     * @param {*} instance
     * @param {*} application
     * @return {*}
     */
    const onClickLoginAzure = (instance, application) => {
        // Avoid Following if no application sended
        if (isEmpty(application)) return;

        // Save in storage the application selected
        localStorage.setItem("application", application);

        // const byPassAzure = localStorage.getItem("testing") === "true";

        // We clear Errors
        setErrors("");

        if (application === APPLICATIONS.APPS) {
            setLoadingApps(true);
            // setApplicationIntent(APPLICATIONS.APPS);
        }

        // if (byPassAzure) {
        //     return handleAzureLogin(accounts, application, true);
        // }

        // Instantiate the login Pop Up Window from Azure AD
        instance.loginPopup().catch((e) => {
            const message = "Se ha cancelado el inicio de sesión. Inténtelo nuevamente.";
            console.log("====== Error: User cancelled the flow.", e);
            setLoadingApps(false);
            setLoadingPMA(false);
            setErrors(message);
        });
    };

    return (
        <>
            <div className="h-screen min-h-screen bg-white flex">
                <div className="w-full lg:w-3/5 flex-col">
                    <div className="h-full relative">
                        <div className="hidden xxl:flex">
                            <J width="19.125em" height="34.5em" fill="none" />
                            <JelouLogoIcon className="absolute inset-0 object-cover m-20" width="11.563em" height="3.063em" fill="none" />
                        </div>
                        <div className="hidden sm:flex xxl:hidden">
                            <J width="12.5em" height="21.875em" fill="none" />
                            <JelouLogoIcon className="absolute inset-0 object-cover ml-14 m-12" width="7.5em" height="3.063em" fill="none" />
                        </div>
                        <div className="flex sm:hidden">
                            <J width="8.75em" height="18.188em" fill="none" style={{ marginTop: -72 }} />
                            <JelouLogoIcon className="absolute inset-0 object-cover mx-7 mt-4" width="6em" height="1.625em" fill="none" />
                        </div>
                        <header className="absolute flex top-0 right-0 justify-end pt-4 pr-4 z-20"></header>

                        <div className="absolute inset-0 flex-1 flex flex-col justify-center lg:flex-none sm:p-0 p-5">
                            <div className="mx-auto w-full max-w-lg z-20">
                                <div className="mt-20 sm:mt-8">
                                    <div className="mb-4">
                                        <BanisiLogoIcon width="500px" height="250px" className="text-pink fill-current" />
                                    </div>
                                    <div className="lineHorizontal"></div>
                                    <div className="flex">
                                        <div className="w-full flex justify-center mt-7">
                                            <button
                                                className="flex social-button justify-center"
                                                disabled={loadingApps || loadingPMA}
                                                onClick={() => onClickLoginAzure(instance, APPLICATIONS.APPS)}>
                                                {loadingApps ? (
                                                    <svg
                                                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-grey-400"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24">
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                ) : (
                                                    <MicrosoftIcon width="1.11em" height="1.11em" className="mr-2" />
                                                )}
                                                Ingresar a Apps
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {!isEmpty(errors) && <div className="mt-4 text-center mr-[1.6rem]">{renderErrors(errors)}</div>}
                            </div>
                            <div className="overflow-hidden xxl:inline-block hidden absolute bottom-0 z-0" style={{ right: "-49%" }}>
                                <svg width="867" height="456" viewBox="0 0 867 456" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="433.5" cy="433.5" r="431" stroke="#00B3C7" strokeOpacity="0.1" strokeWidth="5" />
                                </svg>
                            </div>
                            <div
                                className="overflow-hidden lg:inline-block hidden xxl:hidden absolute bottom-0 z-0"
                                style={{ right: "-49%", top: "53%" }}>
                                <svg width="700" height="456" viewBox="0 0 867 456" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="433.5" cy="433.5" r="431" stroke="#00B3C7" strokeOpacity="0.1" strokeWidth="5" />
                                </svg>
                            </div>
                            <div className="sm:hidden inline-block absolute right-0 bottom-0 z-0" style={{ top: "75%" }}>
                                <svg width="212" height="166" viewBox="0 0 212 166" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="177" cy="177" r="177" fill="white" />
                                    <circle cx="177" cy="177" r="174.5" stroke="#00B3C7" strokeOpacity="0.1" strokeWidth="5" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hidden lg:flex-1 lg:flex items-center justify-center relative">
                    <div className="h-full w-full bg-login">
                        <div className="h-full ml-10 xll:pl-16 flex flex-col justify-end">
                            <div className="mb-20 xxl:mb-40">
                                <div className="font-bold text-white opacity-50 lg:text-xl xxl:text-2xl">
                                    {dayjs().locale(`es`).format("DD MMMM YYYY")}
                                </div>
                                <div className="text-white font-bold text-7xl leading-normal">{time}</div>
                                <div className="text-white lg:text-lg xxl:text-2xl w-3/5 font-semibold">
                                    Ayuda a resolver los
                                    <br /> problemas de tus usuarios
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
